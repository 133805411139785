<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.typeproduct.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.typeproduct.back' | translate}}</button>
                </div>
                <div class="col-md-3 offset-md-6">
                     <button type="button" v-on:click="trashTypeproduct" class="btn btn-warning btn-sm">Delete Element</button>
                </div>
            
            </div>
        
        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.team.new' | translate">
                    <form @submit.prevent="editTypeproduct">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.team.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('description'), 'valid': isSuccessfulDescriptionValid}">
                                                <div class="input-group">
                                                    <input id="description" name="description" v-model="description" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="description">{{'view.team.fields.description' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('description')" class="help text-danger">
                                                        {{ errors.first('description') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                 <div class="row">
                    <div class="col-md-8">
                    </div>
                    <div class="col-md-4">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                </div>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.team.update')}}</button>
                                </div>
                            </div>
                        </div>
                    
                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'

    export default {
        name:     'form-elements',
        data() {
            return {
                name:        '',
                description: '',
                active:1,
            }
        },
        mounted() {
            var id = this.$route.params.id;
            if (id !== null) {
                this.loadTypeproduct(id)
                    .then((data) => {
                        this.name        = data.name;
                        this.description = data.description;
                        this.active = data.active;
                    })
                    .catch(data => {
                        this.addToastMessage({
                            text: 'Ocurrio un error',
                            type: "success"
                        });
                    });
            } else {
                this.addToastMessage({
                    text: 'Ocurrio un error',
                    type: "success"
                });
            }
        },
        methods:  {
            ...mapActions(["loadTypeproduct", "updateTypeproduct", "deleteTypeproduct", "addToastMessage"]),
            back() {
                this.$router.go(-1);
            },
            editTypeproduct() {
                this.$validator.validate().then(result => {
                    if (result) {
                        var id = this.$route.params.id;
                        this.updateTypeproduct({
                            name:        this.name,
                            description: this.description,
                            active: this.active,
                            id:          id
                        })
                            .then((data) => {
                                this.addToastMessage({
                                    text: data.message,
                                    type: "success"
                                });
                                setTimeout(() => this.$router.go(-1), 2000);
                            })
                            .catch(data => {
                                this.error  = data.message;
                                this.errors = data.errors || {};
                            });
                        //this.$refs.vuetable.refresh();
                    }
                })
            },
            trashTypeproduct() {

                var id = this.$route.params.id;
                console.log(id);
                this.deleteTypeproduct({
                    name:        this.name,
                    description: this.description,
                    id:          id
                })
                    .then((data) => {
                        this.addToastMessage({
                            text: data.message,
                            type: "warning"
                        });
                        setTimeout(() => this.$router.go(-1), 100);
                    })
                    .catch(data => {
                        this.error  = data.message;
                        this.errors = data.errors || {};
                    });
                //this.$refs.vuetable.refresh();
            },
            cleanform() {
                this.name        = null;
                this.description = null;
            }

        },
        computed: {
            isSuccessfulNameValid() {
                let isValid = false
                if (this.formFields.name) {
                    isValid = this.formFields.name.validated && this.formFields.name.valid
                }
                return isValid
            }
            ,
            isSuccessfulDescriptionValid() {
                let isValid = false
                if (this.formFields.description) {
                    isValid = this.formFields.description.validated && this.formFields.description.valid
                }
                return isValid
            }
            ,
        }
    }
</script>
